<template>
    <div class="cardListaCompatta">
        <div class="info">
            <div class="text">
                <div class="title4">{{ titolo }}</div>
            </div>
            <div class="iconCont" v-if="showArrow">
                <NIcon size="1.176rem" color="var(--color-text-default)" :component="KeyboardArrowRightRound" :aria-label="$t('freccia')"></NIcon>
            </div>
        </div>
        <a v-if="link && isExternal" class="overlink" :href="link" target="_blank" :aria-label="titolo"></a>
        <NuxtLink v-if="link && !isExternal" class="overlink" :to="localeRoute(link)" :aria-label="titolo"></NuxtLink>
    </div>
</template>

<script lang="ts" setup>
import { NIcon } from 'naive-ui'
import { KeyboardArrowRightRound } from '@vicons/material'
const localeRoute = useLocaleRoute()

interface Props {
    titolo: string,
    showArrow?: boolean,
    link?: any,
    isExternal?: boolean
}
withDefaults(defineProps<Props>(), {
    showArrow: false,
    isExternal: false
})
</script>

<style lang="scss" scoped>
.cardListaCompatta {
    position: relative;

    background-color: var(--color-background-box);
    border-radius: var(--size-card-radius);
    overflow: hidden;

    .info {
        display: grid;
        grid-template-columns: 1fr min-content;
        gap: var(--margin-default);
        padding: var(--margin-default) var(--margin-default) var(--margin-default) var(--margin-double);

        .iconCont {
            display: flex;
            align-items: center;
        }
    }
}

@media screen and (max-width: 600px) {
    .cardListaCompatta {
        .info {
            padding-left: var(--margin-page);
        }
    }
}
</style>